<script lang="ts">
	import Link from "./Link.svelte";
	import arrowRightLong from "../../../core/assets/icons/arrow-right-long.svg?raw";
	import DynamicPicture from "./Picture/DynamicPicture.svelte";
	import type { Image } from "../../../core/schema/Image.js";
	import { getTranslate } from "../utils/getTranslate.js";
	import translations from "./Card.translations.json";
	import { nbspify } from "../../../core/utils/nbspify";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import { textLengthLimit } from "../../../core/utils/textLengthLimit";

	const translate = getTranslate(translations);

	export let toButtonText: string | undefined = undefined;
	export let customButtonText: string | undefined = undefined;
	export let title: string;
	export let description: string;
	export let urlSlug: string;
	export let image: Image | string | undefined = undefined;
	export let squaredImage = true;
	export let containImage = false;
	export let loading: "eager" | "lazy" = "lazy";
</script>

<div class="group">
	<div
		class="border-border flex h-full grow flex-col justify-between gap-4 overflow-hidden rounded-lg border-2 bg-gray-300 p-3 transition-[transform,box-shadow] duration-300 ease-in-out lg:max-w-[31rem] lg:gap-6"
	>
		<div class="flex flex-col gap-6">
			<div class="relative {squaredImage ? 'h-[17.5rem]' : 'h-[12.5rem]'} w-full shrink-0 overflow-hidden rounded-md">
				<a href="/{urlSlug}">
					{#if image}
						{#if typeof image === "string"}
							<StaticPicture
								{image}
								alt={title}
								width={0}
								height={280}
								imgClass="h-full w-full {containImage
									? 'object-contain bg-white'
									: 'object-cover'} rounded-md inset-0 absolute {squaredImage ? 'h-[17.5rem]' : 'h-[12.5rem]'}"
								class={squaredImage ? "h-[17.5rem] " : "h-[12.5rem]"}
								{loading}
							/>
						{:else}
							<DynamicPicture
								{image}
								alt={title}
								width={0}
								height={280}
								imgClass="h-full w-full {containImage
									? 'object-contain bg-white'
									: 'object-cover'} rounded-md inset-0 absolute {squaredImage ? 'h-[17.5rem]' : 'h-[12.5rem]'}"
								class={squaredImage ? "h-[17.5rem]" : "h-[12.5rem]"}
								{loading}
								bucket="storage"
							/>
						{/if}
					{:else}
						<div class="w-full rounded-md bg-gray-400" class:aspect-square={squaredImage}></div>
					{/if}
				</a>
			</div>
			<article class="relative z-[1] flex flex-col gap-2 bg-gray-300 lg:gap-4">
				<a
					class="text-secondary font-spaceGrotesk group-hover:text-primary text-2md row-span-2 m-0 line-clamp-2 p-0 font-bold"
					href="/{urlSlug}"
				>
					{nbspify(title)}
				</a>
				<div class="text-2xs row-span-6 line-clamp-4 tracking-wide lg:line-clamp-3 lg:leading-[1.6]">
					{textLengthLimit(nbspify(description), 250)}
				</div>
			</article>
		</div>
		<div class="flex flex-wrap items-end justify-start">
			<Link
				asButton
				class="font-bold"
				variant="secondary"
				icon={arrowRightLong}
				href="/{urlSlug}"
				aria-label={translate("linkAriaLabel", { title }, true)}
			>
				{#if toButtonText}
					{translate("to")}&nbsp;{toButtonText}
				{:else if customButtonText}
					{customButtonText}
				{:else}
					{translate("moreInfo")}
				{/if}
			</Link>
		</div>
	</div>
</div>
